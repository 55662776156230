import { PROJECT_ENV } from 'app.config';
import Script from 'next/script';
import { Fragment } from 'react';
import { CartProduct } from 'types/Cart';
import { Product } from 'types/Products';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const Gpageview = (url: string) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const logGEvent = ({ action, category, label, value }: GTagEvent) => {
  if (PROJECT_ENV) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

// Add To Wishlist
export function addToWishList(product: Product) {
  window.dataLayer.push({
    event: 'add_to_wishlist',
    content_type: 'product',
    currency: 'EGP',
    value: product.special_price ? product.special_price : product.price,
    // items: [product],
    ecommerce: {
      addToWishlist: {
        items: product,
      },
    },
  });
}

// Add To cart
export function addToCartDataLayer(product: Product) {
  window.dataLayer.push({
    event: 'add_to_cart',
    content_type: 'product',
    currency: 'EGP',
    value: product.special_price ? product.special_price : product.price,
    ecommerce: {
      addToCart: {
        items: product,
      },
    },
  });
}

// Add To cart
// export function deleteFromCartDataLayer(product: CartProduct) {
//   // TODO: Need to modify the shape of product object
//   window.dataLayer.push({
//     event: 'eec.add',
//     currencyCode: 'EGP',
//     content_type: 'product',
//     ecommerce: {
//       add: {
//         actionField: {
//           list: 'Shopping cart',
//         },
//         products: [product],
//       },
//     },
//   });
// }

// View Product Details
export function viewProduct(product: Product) {
  window.dataLayer.push({
    event: 'view_item',
    content_type: 'product',
    currency: 'EGP',
    value: product.special_price ? product.special_price : product.price,
    ecommerce: {
      viewProduct: {
        items: [product],
      },
    },
  });
}

// Init Checkout
export function initCheckout(totalPrice: number, products: CartProduct[]) {
  const theProducts = products.map((item) => ({
    item_id: item.id,
    item_name: item.product.name,
    affliliation: 'Website',
    currency: 'EGP',
    discount: item.product.special_price,
    price: item.product.price,
    quantity: item.quantity,
    // item_brand: item.product,
  }));

  window.dataLayer.push({
    event: 'begin_checkout',
    content_type: 'product',
    currency: 'EGP',
    value: totalPrice,
    ecommerce: {
      beginCheckout: {
        items: theProducts,
      },
    },
  });
}

export function checkout() {
  window.dataLayer.push({
    event: '',
  });
}

// Add Payment Info
export function addPaymentInfo(paymentMethod: string, totalPrice: number, products: CartProduct[]) {
  const theProducts = products.map((item) => ({
    item_id: item.id,
    item_name: item.product.name,
    affliliation: 'Website',
    currency: 'EGP',
    discount: item.product.special_price,
    price: item.product.price,
    quantity: item.quantity,
    // item_brand: item.product,
  }));
  window.dataLayer.push({
    event: 'add_payment_info',
    content_type: 'product',
    currencyCode: 'EGP',
    value: totalPrice,
    payment_type: paymentMethod,
    ecommerce: {
      paymentMethod: {
        items: theProducts,
      },
    },
  });
}

// // Purchase
// export function purchase(orderID: string, price: number, coupon: string, paymentMethod, products, num_items) {
//   window.dataLayer.push({
//     event: 'purchase',
//     transaction_id: orderID,
//     coupon: coupon,
//     content_type: 'product',
//     currencyCode: 'EGP',
//     value: price,
//     payment_type: paymentMethod,
//     num_items: products.length,
//     ecommerce: {
//       purchase: {
//         affiliation: 'Website',
//         items: products,
//       },
//     },
//   });
// }

// // Search
// export function searchQuery(searchQuery) {
//   window.dataLayer.push({
//     event: 'search',
//     search_term: searchQuery,
//   });
// }

// // Search
// export function searchResults(products) {
//   window.dataLayer.push({
//     event: 'eec.searchresults',
//     ecommerce: {
//       detail: {
//         actionField: {
//           list: 'Search Results',
//         },
//         products: [products],
//       },
//     },
//   });
// }

// complete Registration
// export function completeRegistration(name) {
//   window.dataLayer.push({
//     event: 'sign_up',
//     ecommerce: {
//       detail: {
//         actionField: {
//           list: 'Register',
//         },
//         name: name,
//       },
//     },
//   });
// }

export const GTagScript = () =>
  PROJECT_ENV ? (
    <Fragment>
      <Script
        id="GTAG_MANAGER"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WVLJ275W');
        `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WVLJ275W"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      />
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
